import {
  computed,
  ref,
} from '@vue/composition-api';
import store from '@/store';
import toast from '@/utils/toast';
import notificationStoreModule from '../notificationStoreModule';

export default function useNotificationDetailModal(props, emit, refFormObserver) {
  const STORE_MODULE_NAME = 'notification-admin';

  // Register module
  if (!store.hasModule(STORE_MODULE_NAME)) {
    store.registerModule(STORE_MODULE_NAME, notificationStoreModule);
  }

  const toastification = toast();
  const refModalDeleteCashbook = ref(null);
  const refForm = ref(refFormObserver);
  const itemLocal = ref(null);
  const resetItemLocal = () => {
    itemLocal.value = JSON.parse(null);
  };
  const isLoading = ref(false);

  const resetModal = () => {
    resetItemLocal();
  };

  const notificationId = computed(() => props.notificationId);

  const fetchNotificationDetail = () => {
    if (notificationId.value) {
      isLoading.value = true;
      store.dispatch('notification/getNotificationDetail', notificationId.value)
        .then(response => {
          itemLocal.value = response.data;
          isLoading.value = false;
        })
        .catch(error => {
          toastification.showToastError(error);
          isLoading.value = true;
        });
    }
  };

  const onOpen = () => {
    resetModal();
    fetchNotificationDetail();
  };

  // UI
  const resolveMethods = method => {
    if (method === 'app') {
      return { title: 'App', variant: 'light-primary' };
    }
    if (method === 'email') {
      return { title: 'Email', variant: 'light-info' };
    }
    if (method === 'zalo') {
      return { title: 'Zalo', variant: 'light-success' };
    }
    if (method === 'sms') {
      return { title: 'SMS', variant: 'light-secondary' };
    }
    return { title: 'App', variant: 'light-primary' };
  };

  return {
    refModalDeleteCashbook,
    refForm,
    itemLocal,
    resetItemLocal,
    resetModal,
    fetchNotificationDetail,
    onOpen,
    isLoading,
    resolveMethods,
  };
}
