<template>
  <b-modal
    id="modal-notification"
    ref="refModal"
    title="Thông báo"
    size="lg"
    :ok-title="isSubmitting ? 'Đang lưu' : 'Lưu'"
    :ok-disabled="isSubmitting"
    cancel-title="Huỷ"
    cancel-variant="outline-secondary"
    :hide-header-close="true"
    no-close-on-backdrop
    scrollable
    @show="resetModal"
    @hidden="resetModal"
    @ok="onSubmit"
  >
    <!-- Body -->
    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <b-alert
        show
        variant="warning"
      >
        <div class="alert-body">
          <feather-icon
            class="mr-25"
            icon="StarIcon"
          />
          <span class="ml-25">Thông báo đã tạo chỉ được cập nhật lại nội dung gửi. Người nhận hoặc phương thức gửi sẽ không thể cập nhật.</span>
        </div>
      </b-alert>
      <!-- Form -->
      <b-form
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <b-row>
          <b-col
            cols="12"
            class="my-1"
          >
            <div class="d-flex justify-space-between">
              <div class="d-flex justify-space-between font-small-4 font-weight-bolder text-uppercase text-primary">
                1. Thông tin chung
              </div>
            </div>
          </b-col>
        </b-row>
        <!-- Title -->
        <validation-provider
          #default="validationContext"
          name="Tiêu đề"
          rules="required"
        >
          <b-form-group
            label="Tiêu đề"
            class="mt-2"
          >
            <template
              v-slot:label
            >
              Tiêu đề
              <span
                class="text-danger"
              >
                (*)
              </span>
            </template>
            <b-form-input
              id="title"
              v-model="itemLocal.title"
              :state="getValidationState(validationContext)"
              trim
            />

            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <!-- Note -->
        <validation-provider
          #default="validationContext"
          name="Nội dung"
          rules="required"
        >
          <b-form-group
            label="Nội dung thông báo"
          >
            <template
              v-slot:label
            >
              Nội dung
              <span
                class="text-danger"
              >
                (*)
              </span>
            </template>
            <editor
              v-model="itemLocal.content"
              :default-value="itemLocal.content"
            />
            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <media-view
          v-model="itemLocal.attachments"
          label="Đính kèm"
          :initial-value="itemLocal.attachments"
          @on-delete-uploaded-file="onDeleteAttachment"
        />

        <b-row class="mb-1">
          <b-col
            cols="12"
            class="mt-2"
          >
            <div class="d-flex justify-space-between">
              <div class="d-flex justify-space-between font-small-4 font-weight-bolder text-uppercase text-primary">
                2. Nơi nhận
              </div>
              <b-button
                v-show="!itemLocal.id || itemLocal.id <= 0"
                v-b-modal.modal-select-recipent
                variant="primary"
                class="btn-icon ml-auto"
                size="sm"
              >
                <feather-icon icon="UserPlusIcon" />
              </b-button>
            </div>
          </b-col>
          <b-col>
            <small class="text-muted">Bỏ chọn nếu muốn gửi cho tất cả tòa nhà</small>
          </b-col>
        </b-row>
        <recipent-table :rows="itemLocal.tenants" />

        <b-row class="mt-1">
          <b-col
            cols="12"
          >
            <div class="d-flex justify-space-between">
              <div class="d-flex justify-space-between font-small-4 font-weight-bolder text-uppercase text-primary">
                3. Hình thức gửi
              </div>
            </div>
          </b-col>
        </b-row>
        <b-row class="mb-2">
          <b-col>
            <validation-provider
              #default="validationContext"
              name="Hình thức gửi"
              rules="required"
            >
              <b-form-checkbox-group
                v-model="itemLocal.methods"
                :disabled="itemLocal.id > 0"
                :options="[
                  { text: 'Ứng dụng Resident', value: 'app' },
                  { text: 'Email', value: 'email' },
                ]"
                class="demo-inline-spacing"
              />
              <small class="text-danger">{{ validationContext.errors[0] }}</small>
            </validation-provider>

          </b-col>
        </b-row>

      </b-form>
    </validation-observer>

    <select-recipent-modal
      :tenants-selected="itemLocal.tenants"
      @selectTenant="onSelectTenants"
    />
  </b-modal>
</template>

<script>
import {
  BModal,
  BFormGroup,
  BFormInput,
  BForm,
  BFormInvalidFeedback,
  BCol,
  BRow,
  BFormCheckboxGroup,
  BAlert,
  BButton,
} from 'bootstrap-vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { required } from '@validations';
import formValidation from '@core/comp-functions/forms/form-validation';
import Editor from '@/views/components/Editor.vue';
import MediaView from '@/views/components/media/MediaView.vue';
import useNotificationModal from './useNotificationModal';
import SelectRecipentModal from './select-recipent/SelectRecipentModal.vue';
import RecipentTable from './RecipentTable.vue';

export default {
  components: {
    BModal,
    BFormGroup,
    BFormInput,
    BForm,
    BFormInvalidFeedback,
    ValidationProvider,
    ValidationObserver,
    Editor,
    MediaView,
    BAlert,
    BCol,
    BRow,
    BFormCheckboxGroup,
    BButton,
    SelectRecipentModal,
    RecipentTable,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
    };
  },
  setup(props, { emit }) {
    const {
      refFormObserver, getValidationState, resetForm, clearForm,
    } = formValidation();

    const {
      refModal,
      itemLocal,
      isSubmitting,
      resetItemLocal,
      resetModal,
      onDeleteAttachment,
      onSubmit,
      onSelectTenants,
    } = useNotificationModal(props, emit, refFormObserver);

    return {
      refModal,
      itemLocal,
      isSubmitting,
      resetItemLocal,
      resetModal,
      onSubmit,
      onDeleteAttachment,

      // Form Validation
      resetForm,
      clearForm,
      refFormObserver,
      getValidationState,
      onSelectTenants,
    };
  },
};
</script>
