import { ref } from '@vue/composition-api';
import store from '@/store';
import toast from '@/utils/toast';
import notificationStoreModule from '../notificationStoreModule';

export default function useNotificationModal(props, emit, refFormObserver) {
  const STORE_MODULE_NAME = 'notification';

  // Register module
  if (!store.hasModule(STORE_MODULE_NAME)) {
    store.registerModule(STORE_MODULE_NAME, notificationStoreModule);
  }

  const toastification = toast();

  const refModal = ref(null);
  const refForm = ref(refFormObserver);
  const itemLocal = ref(JSON.parse(JSON.stringify(props.item)));
  const resetItemLocal = () => {
    itemLocal.value = JSON.parse(JSON.stringify(props.item));
  };
  const isSubmitting = ref(false);
  const resetModal = () => {
    isSubmitting.value = false;
    resetItemLocal();
  };

  const onSubmit = async bvModalEvt => {
    bvModalEvt.preventDefault();

    const success = await refForm.value.validate();
    if (!success) {
      return;
    }

    const formData = new FormData();

    formData.append('title', itemLocal.value.title);
    formData.append('content', itemLocal.value.content);
    // eslint-disable-next-line no-restricted-syntax
    formData.append('methods', itemLocal.value.methods);

    // eslint-disable-next-line no-restricted-syntax
    for (const tenant of itemLocal.value.tenants) {
      formData.append('recipentIds[]', tenant.id);
    }

    // eslint-disable-next-line no-restricted-syntax
    for (const attachment of itemLocal.value.attachments) {
      if (attachment.file) {
        formData.append('attachments', attachment.file);
      }
    }

    if (itemLocal.value.deleteAttachmentIds && itemLocal.value.deleteAttachmentIds.length > 0) {
      formData.append('deleteAttachmentIds', itemLocal.value.deleteAttachmentIds);
    }

    isSubmitting.value = true;

    if (itemLocal.value.id && itemLocal.value.id > 0) {
      store
        .dispatch('notification/updateNotification', { id: itemLocal.value.id, data: formData })
        .then(response => { emit('on-item-updated', response.data); isSubmitting.value = false; })
        .then(() => {
          refModal.value.toggle('#toggle-btn');
        })
        .then(() => {
          emit('refetch-data');
        })
        .then(() => {
          toastification.showToastUpdateSuccess();
        })
        .catch(error => {
          toastification.showToastError(error, refForm.value);
          isSubmitting.value = false;
        });
    } else {
      store
        .dispatch('notification/createNotification', formData)
        .then(response => { emit('on-item-created', response.data); isSubmitting.value = false; })
        .then(() => {
          refModal.value.toggle('#toggle-btn');
        })
        .then(() => {
          emit('refetch-data');
        })
        .then(() => {
          toastification.showToastCreateSuccess();
        })
        .catch(error => {
          toastification.showToastError(error, refForm.value);
          isSubmitting.value = false;
        });
    }
  };

  const onDeleteAttachment = id => {
    if (itemLocal.value.deleteAttachmentIds && itemLocal.value.deleteAttachmentIds.length > 0) {
      itemLocal.value.deleteAttachmentIds.push(id);
    } else {
      itemLocal.value.deleteAttachmentIds = [id];
    }
  };

  const onSelectTenants = val => {
    itemLocal.value.tenants = [...itemLocal.value.tenants, ...val];
  };

  return {
    refModal,
    refForm,
    itemLocal,
    isSubmitting,
    resetItemLocal,
    resetModal,
    onSubmit,
    onDeleteAttachment,
    onSelectTenants,
  };
}
