<template>
  <b-modal
    id="modal-notification-detail"
    ref="refModalNotificationDetail"
    title="Chi tiết thông báo"
    size="lg"
    scrollable
    :hide-footer="true"
    no-close-on-backdrop
    @show="onOpen"
    @hidden="resetModal"
  >

    <div class="d-flex justify-content-center mb-1">
      <b-spinner
        v-if="isLoading"
        class="text-center"
        variant="secondary"
      />
    </div>

    <div v-if="itemLocal && !isLoading">
      <div class="d-flex justify-space-between font-small-4 font-weight-bolder text-uppercase text-primary pb-1">
        1. Thông tin chung
      </div>
      <b-list-group class="">
        <b-list-group-item>
          <b-row>
            <b-col class="font-weight-bolder">
              Tiêu đề:
            </b-col>
            <b-col class=" ">
              {{ itemLocal.title }}
            </b-col>
          </b-row>
        </b-list-group-item>

        <b-list-group-item>
          <b-row>
            <b-col class="font-weight-bolder">
              Nội dung:
            </b-col>
            <b-col class="">
              {{ itemLocal.contentPlain }}
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item>
          <b-row>
            <b-col class="font-weight-bolder">
              Hình thức gửi:
            </b-col>
            <b-col class="">
              <b-badge
                v-for="(method, index) in itemLocal.methods"
                :key="index"
                :variant="resolveMethods(method).variant"
                class="font-small-1 mr-50"
              >
                <span>{{ resolveMethods(method).title }}</span>
              </b-badge>
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item>
          <b-row>
            <b-col class="font-weight-bolder">
              Trạng thái:
            </b-col>
            <b-col class="">
              <b-badge
                class="font-small-1"
                :variant="`light-${itemLocal.status.variant}`"
              >
                {{ itemLocal.status.title }}
              </b-badge>
              <small
                v-if="itemLocal.errorMessage"
                class="text-muted"
              >
                <br>{{ itemLocal.errorMessage }}
              </small>
            </b-col>
          </b-row>
        </b-list-group-item>

      </b-list-group>
      <div class="d-flex justify-space-between font-small-4 font-weight-bolder text-uppercase text-primary pb-1 pt-2">
        2. Người nhận
      </div>
      <b-list-group class="">
        <b-list-group-item
          v-for="(tenant, index) in itemLocal.tenants"
          :key="index"
        >
          <b-row>
            <b-col class="font-weight-bolder">
              {{ tenant.name }}
            </b-col>
            <b-col class=" ">
              {{ tenant.phone }}
            </b-col>
            <!-- <b-col class="">
              {{ tenant.email ?? "" }}
            </b-col> -->
          </b-row>
        </b-list-group-item>

      </b-list-group>
    </div>
    <!-- Body -->

  </b-modal>
</template>

<script>
import {
  BModal,
  BRow,
  BCol,
  BListGroup,
  BListGroupItem,
  BSpinner,
  BBadge,
} from 'bootstrap-vue';
import { required } from '@validations';
import formValidation from '@core/comp-functions/forms/form-validation';
import useNotificationDetailModal from './useNotificationDetailModal';

export default {
  components: {
    BModal,
    BRow,
    BCol,
    BListGroup,
    BListGroupItem,
    BSpinner,
    BBadge,
  },
  props: {
    notificationId: {
      type: Number,
      default: 0,
      required: true,
    },
  },
  data() {
    return {
      required,
    };
  },
  setup(props, { emit }) {
    const {
      refFormObserver, getValidationState, resetForm, clearForm,
    } = formValidation();

    const {
      refModalDeleteCashbook,
      itemLocal,
      resetItemLocal,
      resetModal,
      onOpen,
      isLoading,
      resolveMethods,
    } = useNotificationDetailModal(props, emit, refFormObserver);

    return {
      refModalDeleteCashbook,
      itemLocal,
      resetItemLocal,
      resetModal,
      // Form Validation
      resetForm,
      clearForm,
      refFormObserver,
      getValidationState,
      onOpen,
      isLoading,
      resolveMethods,
    };
  },
};
</script>

<style lang="scss">
  @import "@core/scss/vue/libs/vue-good-table.scss";
  .vgt-wrap {
    .vgt-table {
      thead {
        th {
          font-size: 1rem !important;
          font-weight: 500;
        }
      }
      td {
        font-size: 1rem !important;
        font-weight: 500;
      }
    }
  }
  </style>
