<template>
  <vue-good-table
    :columns="columns"
    :rows="rows"
    :select-options="{
      enabled: false,
      selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
      selectionInfoClass: 'custom-class',
      selectionText: 'rows selected',
      clearSelectionText: 'clear',
      disableSelectInfo: true, // disable the select info panel on top
      selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
    }"
  >
    <template slot="loadingContent">
      <b-spinner
        label="Loading"
        type="grow"
      />
    </template>
    <template
      slot="table-row"
      slot-scope="props"
    >
      <!-- Column: isRepresent -->
      <span v-if="props.column.field === 'apartment'">
        {{ resolveApartmentName(props.row.residenceHistories) }}
      </span>

      <!-- Column: Common -->
      <span v-else>
        {{ props.formattedRow[props.column.field] }}
      </span>
    </template>
    <div slot="emptystate">
      <div class="text-center text-muted">
        Không có bản ghi nào!
      </div>
    </div>
  </vue-good-table>
</template>

<script>
import {
  BSpinner,
} from 'bootstrap-vue';
import { VueGoodTable } from 'vue-good-table';

export default {
  components: {
    BSpinner,

    VueGoodTable,
  },
  props: {
    rows: {
      type: Array,
      default: () => [
      ],
    },
  },
  setup() {
    // Table Handlers
    const columns = [
      {
        label: 'Họ tên',
        field: 'name',
        sortable: false,
      },
      {
        label: 'Số điện thoại',
        field: 'phone',
        sortable: false,
      },
      {
        label: 'Email',
        field: 'email',
        sortable: false,
      },
      {
        label: 'Tòa nhà',
        field: 'apartment',
        sortable: false,
      },
    ];

    return {
      columns,
    };
  },

  methods: {
    resolveApartmentName(val) {
      if (val && val.length > 0) {
        const item = val[0];
        const arr = [];
        if (item.apartment) {
          arr.push(`Tòa nhà: ${item.apartment.name}`);
        }
        if (item.room) {
          arr.push(`Phòng: ${item.room.name}`);
        }
        return arr.join(", ");
      }
      return "";
    },
  },
};
</script>
